/* For all pages */
.all-page-container {
    /* width: 98%;
    max-width: 1200px;
    height: 100%;
    max-height: 700px; 
    overflow: auto;*/
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: top;
    padding-bottom: 25px;
    /* min-height: calc(100vh - 130px); */
    /* max-width: calc(100vw - 267px); */
    /* width: 100%; */
    /* min-width: calc(100vw - 286px); */
    /* padding-top: 25px; */
    /* width: calc(100vw - 240px); */
    /* vertical-align: top; */
}
.loading-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* min-height: calc(100vh - 130px); */
    margin-top: 120px;
    /* max-width: calc(100vw - 267px); */
   /*  height: 100%;
    width: 100%;
    background-color: gray; */
}