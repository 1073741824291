
.dropzone {
  border: 3px dashed gray;
  height: 150px;
  width: 600px;
  margin: 0 auto;
}

.center {
  position:relative;
  top: 20%;
  width: 100%;
  text-align: center;
  font-size: 18px;
}

.centerBtn {
  margin: 0 auto;
  position: relative;
  /* left: 0; */
  top: "85px";
  z-index: 1;

}